<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" :title="'Opportunità - ' + data.finding.id" size="lg">
            <b-form-group label="Opportunità">
                <b-row>
                    <b-col v-for="(item, index) in data.finding.languages" :key="index">
                        <b-input-group :prepend="item.lang">
                            <b-form-textarea v-model="item.finding" rows="3"/>
                        </b-input-group>
                        <b-btn variant="secondary" size="sm" class="mt-2" v-show="item.finding !== null && item.finding === item.finding.toUpperCase()" @click="item.finding = item.finding.toLowerCase()">Minuscolo</b-btn>
                    </b-col>
                </b-row>
            </b-form-group>
            <b-form-group label="Punti norma">
                <b-form-checkbox-group v-model="data.finding.standardspoints">
                <div v-for="(item, index) of data.points" :key="index">
                    <strong>{{ item.standard }}: </strong>
                    <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                </div>
                </b-form-checkbox-group>
            </b-form-group>
            <b-form-group label="Veto">
                <b-table no-border-collapse responsive :items="data.veto" :fields="fields"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.finding.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditfinding",
    data() {
        return {
            show: false,
            data: null,
            fields: [{
                key: 'datetime',
                label: 'Data'
            },{
                key: 'fullname',
                label: 'Veto'
            },{
                key: 'decision',
                label: 'Decisione'
            },{
                key: 'note',
                label: "Note"
            }]
        }
    },
    methods: {
        loadData(auditsId, id){
            this.openHttp2("audits/findings/finding", { audits_id: auditsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp2("audits/findings/finding/save", this.data.finding).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp2("audits/findings/finding/delete", { audits_id: this.data.finding.audits_id, id: this.data.finding.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        saveVeto(auditsId, id, decision, note){
            this.saveHttp2("audits/findings/finding/vetosave", { audits_id: auditsId, id: id, decision: decision, note: note }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        unlockVeto(auditsId, id){
            this.saveHttp2("audits/findings/finding/vetounlock", { audits_id: auditsId, id: id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>